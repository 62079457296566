import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ArenaContactComponent } from '@fca-app/dashboard/components/home/arena-list/arena-details/arena-contact-person/arena-contact.component';
import { ArenaDetailsComponent } from '@fca-app/dashboard/components/home/arena-list/arena-details/arena-details-component';
import { ArenaEventManagePpvComponent } from '@fca-app/dashboard/components/home/arena-list/arena-details/arena-events/arena-event/arena-event-manage-ppv/arena-event-manage-ppv.component';
import { ArenaEventSlotCardComponent } from '@fca-app/dashboard/components/home/arena-list/arena-details/arena-events/arena-event/arena-event-slot-card/arena-event-slot-card.component';
import { ArenaEventSlotEmptyComponent } from '@fca-app/dashboard/components/home/arena-list/arena-details/arena-events/arena-event/arena-event-slot-empty/arena-event-slot-empty.component';
import { ArenaEventComponent } from '@fca-app/dashboard/components/home/arena-list/arena-details/arena-events/arena-event/arena-event.component';
import { ResultsModalComponent } from '@fca-app/dashboard/components/home/arena-list/arena-details/arena-events/arena-event/results-modal/results-modal.component';
import { StartStopEventComponent } from '@fca-app/dashboard/components/home/arena-list/arena-details/arena-events/arena-event/video-link-input/start-stop-event.component';
import { ArenaEventsComponent } from '@fca-app/dashboard/components/home/arena-list/arena-details/arena-events/arena-events.component';
import { ArenaInfoComponent } from '@fca-app/dashboard/components/home/arena-list/arena-details/arena-info/arena-info.component';
import { ArenaPhotoComponent } from '@fca-app/dashboard/components/home/arena-list/arena-details/arena-photo/arena-photo.component';
import { VideoViewerComponent } from '@fca-app/dashboard/components/home/arena-list/arena-details/video-viewer/video-viewer.component';
import { ArenaGalleryComponent } from '@fca-app/dashboard/components/home/arena-list/arena-gallery/arena-gallery.component';
import { ArenaListComponent } from '@fca-app/dashboard/components/home/arena-list/arena-list.component';
import { FightsService } from '@fca-app/services/fights.service';
import { SharedModule } from '@fca-app/shared/shared.module';

@NgModule({
    declarations: [
        ArenaDetailsComponent,
        ArenaPhotoComponent,
        ArenaContactComponent,
        ArenaInfoComponent,
        ArenaEventsComponent,
        ArenaListComponent,
        ArenaGalleryComponent,
        ArenaEventComponent,
        ArenaEventSlotCardComponent,
        ArenaEventSlotEmptyComponent,
        ArenaEventManagePpvComponent,
        VideoViewerComponent,
        StartStopEventComponent,
        ResultsModalComponent,
    ],
    imports: [FormsModule, SharedModule, RouterModule, ReactiveFormsModule],
    providers: [FightsService],
    exports: [ArenaListComponent],
})
export class ArenasModule {}

<div>
    <h3 nz-typography>Arena info</h3>
    <form nz-form>
        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="name">Arena name:</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <input
                    nz-input
                    [(ngModel)]="data.name"
                    (ngModelChange)="onChange()"
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Arena name"
                    required
                />
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="address">Arena address:</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <input
                    nz-input
                    [(ngModel)]="data.address.formattedAddress"
                    id="address"
                    name="address"
                    type="text"
                    (click)="onAddressChange()"
                    placeholder="Arena address"
                />
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="link">Website:</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <input
                    nz-input
                    [(ngModel)]="data.link"
                    (ngModelChange)="onChange()"
                    name="link"
                    type="url"
                    placeholder="https://example.com"
                />
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="description">Description:</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <textarea
                    nz-input
                    [(ngModel)]="data.description"
                    (ngModelChange)="onChange()"
                    name="description"
                    type="text"
                    placeholder="Description"
                ></textarea>
            </nz-form-control>
        </nz-form-item>
    </form>
</div>

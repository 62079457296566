import { FightEventPrice } from '@fca-app/api/fca/arenas/interfaces/response/arena-event-api.response';
import { EventStatus } from '@fca-app/enums/event-status.enum';
import { FightEventImageModel } from '@fca-app/models/images/fight-event-image/fight-event-image.model';
import { LocationModel } from '@fca-app/models/location/location.model';
import { FightEventConferenceModel } from '@fca-app/models/users/arena/fight-event/fight-event-conference/fight-event-conference.model';
import {
    FightEventStartInfoModel,
    FightEventStartInfoModelData,
} from '@fca-app/models/users/arena/fight-event/fight-event-start-info/fight-event-start-info.model';
import { FightEventWeightingModel } from '@fca-app/models/users/arena/fight-event/fight-event-weighting/fight-event-weighting.model';
import { FightTimeSlotModel } from '@fca-app/models/users/arena/fight-event/fight-time-slot/fight-time-slot.model';
import * as moment from 'moment-timezone';

export interface FightEventModelData extends FightEventStartInfoModelData {
    id: string;
    name: string;
    hidden: boolean;
    location?: LocationModel;
    fightSlots: FightTimeSlotModel[];
    image?: FightEventImageModel;
    conference?: FightEventConferenceModel;
    weighting?: FightEventWeightingModel;
    matchingRequests: string[];
    isPPV: boolean;
    prices: FightEventPrice[];
}

export interface FightEventModel extends FightEventModelData {}

export class FightEventModel extends FightEventStartInfoModel {
    isFirstSlot(slot: FightTimeSlotModel): boolean {
        return this.fightSlots.length > 0 && this.fightSlots[0] === slot;
    }

    isLastSlot(slot: FightTimeSlotModel): boolean {
        return this.fightSlots[this.fightSlots.length - 1] === slot;
    }

    isFighterExistsInEvent(fighterId: number): boolean {
        return this.fightSlots.some(
            slot => slot.fight && (slot.fight.initiator.id === fighterId || slot.fight.target.id === fighterId)
        );
    }

    isLive(): boolean {
        return this.status === EventStatus.Ongoing;
    }

    get fromToTimeString(): string {
        if (!this.location?.tz) {
            return 'Need to set a timezone';
        }
        return (
            moment(this.fromDate)
                .tz(this.location.tz)
                .format('HH:mm') +
            ' - ' +
            moment(this.toDate)
                .tz(this.location.tz)
                .format('HH:mm')
        );
    }

    get fromToDateString(): string {
        if (!this.location?.tz) {
            return 'Need to set a timezone';
        }
        return moment(this.fromDate)
            .tz(this.location.tz)
            .format('ddd DD MMM YYYY');
    }
}

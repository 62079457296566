import { ArenaEventApiResponse } from '@fca-app/api/fca/arenas/interfaces/response/arena-event-api.response';
import { FightEventImageFactory } from '@fca-app/models/images/fight-event-image/fight-event-image.factory';
import { FightEventImageMapper } from '@fca-app/models/images/fight-event-image/fight-event-image.mapper';
import { LocationFactory } from '@fca-app/models/location/location.factory';
import { LocationMapper } from '@fca-app/models/location/location.mapper';
import { FightEventConferenceFactory } from '@fca-app/models/users/arena/fight-event/fight-event-conference/fight-event-conference.factory';
import { FightEventConferenceMapper } from '@fca-app/models/users/arena/fight-event/fight-event-conference/fight-event-conference.mapper';
import { FightEventStartInfoMapper } from '@fca-app/models/users/arena/fight-event/fight-event-start-info/fight-event-start-info.mapper';
import { FightEventWeightingFactory } from '@fca-app/models/users/arena/fight-event/fight-event-weighting/fight-event-weighting.factory';
import { FightEventWeightingMapper } from '@fca-app/models/users/arena/fight-event/fight-event-weighting/fight-event-weighting.mapper';
import { FightEventModelData } from '@fca-app/models/users/arena/fight-event/fight-event.model';
import { FightTimeSlotFactory } from '@fca-app/models/users/arena/fight-event/fight-time-slot/fight-time-slot.factory';
import { FightTimeSlotMapper } from '@fca-app/models/users/arena/fight-event/fight-time-slot/fight-time-slot.mapper';

export class FightEventMapper {
    mapData(raw: ArenaEventApiResponse): FightEventModelData {
        const image = raw.images.pop();

        return {
            ...new FightEventStartInfoMapper().mapData(raw),
            id: raw.id,
            name: raw.name,
            hidden: raw.hidden,
            isPPV: raw.ppv,
            prices: raw.prices || [],
            ...(raw.location && {
                location: new LocationFactory().getModelFromData(new LocationMapper().mapData(raw.location)),
            }),
            fightSlots: (raw.fightSlots || [])
                .map(fs => new FightTimeSlotFactory().getModelFromData(new FightTimeSlotMapper().mapData(fs, '')))
                .reverse(),
            ...(image && {
                image: new FightEventImageFactory().getModelFromData(new FightEventImageMapper().mapData(image)),
            }),
            ...(raw.conference && {
                conference: new FightEventConferenceFactory().getModelFromData(
                    new FightEventConferenceMapper().mapData(raw.conference)
                ),
            }),
            ...(raw.weighting && {
                weighting: new FightEventWeightingFactory().getModelFromData(
                    new FightEventWeightingMapper().mapData(raw.weighting)
                ),
            }),
            matchingRequests: raw.fightEventRequests || [],
        };
    }
}

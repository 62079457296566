<div>
    <nz-select
        *ngIf="timeSlot"
        (ngModelChange)="changeWinner($event)"
        nzPlaceHolder="Select winner"
        [(ngModel)]="result.winner"
    >
        <nz-option
            nzLabel="{{ timeSlot.fight!.initiator.firstName + ' ' + timeSlot.fight!.initiator.lastName }}"
            [nzValue]="EFightWinner.INITIATOR"
        >
        </nz-option>
        <nz-option
            nzLabel="{{ timeSlot.fight!.target.firstName + ' ' + timeSlot.fight!.target.lastName }}"
            [nzValue]="EFightWinner.TARGET"
        >
        </nz-option>
        <nz-option nzLabel="" nzDisabled></nz-option>
        <nz-option nzLabel="Draw" [nzValue]="EFightWinner.BOTH"></nz-option>
    </nz-select>
    <nz-select
        *ngIf="result.winner"
        (ngModelChange)="changeResult($event)"
        nzPlaceHolder="Select result"
        style="margin-top: 0.2rem"
        [(ngModel)]="result.result"
    >
        <nz-option *ngFor="let key of resultTypes" nzLabel="{{ key }}" [nzValue]="key"></nz-option>
    </nz-select>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeocodingModel } from '@fca-app/models/geocoding/geocoding.model';
import { UserImageModel } from '@fca-app/models/images/user-image/user-image.model';
import { LocationAddressFactory } from '@fca-app/models/location-address/location-address.factory';
import { ArenaFactory } from '@fca-app/models/users/arena/arena.factory';
import { ArenaModel } from '@fca-app/models/users/arena/arena.model';
import { ArenasService } from '@fca-app/services/arenas.service';
import { EAdminPermissions } from '@fca-app/shared/enums/admin-permissions.enum';
import { EImageType } from '@fca-app/shared/enums/image-type.enum';
import { untilDestroy, UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { FcaPreloadService } from '@fca-app/shared/services/fca-preload.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'app-arena-details',
    templateUrl: './arena-details-component.html',
    styleUrls: ['./arena-details-component.scss'],
})
export class ArenaDetailsComponent implements OnInit, OnDestroy {
    readonly capacityArenaPhotos = 3;
    private initialData: ArenaModel;

    imageTypes = EImageType;
    id: number;
    data: ArenaModel;
    isModalVisible = false;
    isChangedSomeValues$ = new BehaviorSubject<boolean>(false);
    permissions = EAdminPermissions;

    constructor(
        public readonly preloadService: FcaPreloadService,
        private readonly arenasService: ArenasService,
        private readonly notificationService: NzNotificationService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        if (this.router.getCurrentNavigation()?.extras.state as ArenaModel) {
            const routerData = this.router.getCurrentNavigation()?.extras.state as ArenaModel;
            if (routerData.id > -1) {
                this.setArenaData(routerData);
            }
        }
        this.route.params.pipe(untilDestroy(this)).subscribe(params => {
            this.id = Number(params['id']);
            this.init(this.id);
        });
    }

    get uploadedPhotos(): UserImageModel[] {
        return (this.data?.images || []).filter(image => image.original && image.imageType !== EImageType.ARENA_AVATAR);
    }

    get avatarPhoto(): UserImageModel | null {
        const [img] = (this.data?.images || []).filter(
            image => image.original && image.imageType === EImageType.ARENA_AVATAR
        );
        return img || null;
    }

    get photos(): (UserImageModel | null)[] {
        const arr = new Array(this.capacityArenaPhotos);
        const photos = this.uploadedPhotos;

        arr.fill(null);
        for (let i = 0; i < this.uploadedPhotos.length; i++) {
            arr[photos[i].positionId] = photos[i];
        }

        return arr;
    }

    init(id: number) {
        this.preloadService.preload(true);
        this.arenasService
            .getArenaById(id)
            .pipe(take(1), untilDestroy(this))
            .subscribe(arena => {
                this.setArenaData(arena);
                this.isChangedSomeValues$.next(false);
                this.preloadService.preload(false);
            });
    }

    activateArena() {
        this.arenasService
            .approveArena(this.data.location.id)
            .pipe(take(1), untilDestroy(this))
            .subscribe(() => {
                this.init(this.id);
            });
    }

    get someValueHasBeenChanged(): boolean {
        return false; // JSON.stringify(this.data) === JSON.stringify(this.initialData);
    }

    onUpdateClick() {
        this.arenasService
            .updateArenaInfo(this.data)
            .pipe(take(1), untilDestroy(this))
            .subscribe(data => {
                this.setArenaData(data);
                this.notificationService
                    .success('Success', '', { nzDuration: 1500 })
                    .onClick.pipe(take(1), untilDestroy(this))
                    .subscribe();
            });
    }

    cancel() {
        this.data = new ArenaFactory().getModelFromData(this.initialData);
        this.isChangedSomeValues$.next(false);
    }

    openModal() {
        this.isModalVisible = true;
    }

    onAddressChange(data: GeocodingModel) {
        this.handleModalCancel();
        this.data.location.address = new LocationAddressFactory().getModelFromData({
            formattedAddress: data.formattedAddress,
            latitude: data.location.lat,
            longitude: data.location.lng,
            city: data.fullAddress.address.city,
            street: data.fullAddress.address.street,
            building: data.fullAddress.address.building,
            country: data.fullAddress.address.country,
        });

        this.isChangedSomeValues$.next(this.someValueHasBeenChanged);
    }

    handleModalCancel() {
        this.isModalVisible = false;
    }

    ngOnInit() {}

    ngOnDestroy() {}

    private setArenaData(arena: ArenaModel): void {
        this.data = arena;
        this.initialData = new ArenaFactory().getModelFromData(arena);
    }
}
